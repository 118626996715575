import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';

import { environment } from 'environments/environment';

export const xeroConfig: OpenIdConfiguration = {
  configId: 'auth-config-xero',
  authority: 'https://identity.xero.com/',
  clientId: environment.xero.clientId,
  redirectUrl: `${window.location.origin}${environment.xero.redirectPath}`,
  responseType: 'code',
  scope:
    `openid profile email offline_access ` +
    `payroll.timesheets accounting.settings.read payroll.employees.read payroll.settings.read ` +
    `accounting.contacts accounting.transactions`,
  authWellknownEndpoints: {
    issuer: 'https://identity.xero.com',
    authorizationEndpoint: 'https://login.xero.com/identity/connect/authorize',
    userInfoEndpoint: 'https://identity.xero.com/connect/userinfo',
    endSessionEndpoint: 'https://login.xero.com/identity/connect/endsession',
    tokenEndpoint: 'https://identity.xero.com/connect/token',
  },
  logLevel: LogLevel.None,
};

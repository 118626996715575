import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';

import { CreateConnectionMetadata, IntegrationType, Maybe } from '@site-mate/sitemate-flowsite-shared';

import { ExternalAuthService } from 'app/core/services/external-auth.service';
import { IntegrationService } from 'app/core/services/integration.service';

import { getOAuthConfig, OAuthCallbacks } from '../../config/oauth-callbacks';

@Component({
  selector: 'fs-external-auth-redirect',
  template: '',
})
export class ExternalAuthRedirectComponent implements OnInit {
  private readonly externalAuthService = inject(ExternalAuthService);
  private readonly integrationService = inject(IntegrationService);
  private readonly route = inject(ActivatedRoute);

  async ngOnInit() {
    const integrationType = Array.from(OAuthCallbacks.keys()).find(
      (integrationKey) => this.route.snapshot.paramMap.get('integration') === integrationKey,
    );

    const workspaceId = this.externalAuthService.getRedirectWorkspaceId();
    console.debug('🚀 ~ ExternalAuthRedirectComponent ~ ngOnInit ~ workspaceId:', workspaceId);

    if (!integrationType) {
      // TODO: Redirect to error page
      return;
    }

    const integration = await lastValueFrom(this.integrationService.getIntegration(integrationType));
    const authConfigId = getOAuthConfig(integration.type)?.configId;

    if (!authConfigId) {
      throw new Error(
        `${ExternalAuthRedirectComponent.name}: No auth config ID found for the integration type`,
      );
    }

    this.externalAuthService.checkAuth(window.location.href, authConfigId).subscribe((loginResponse) => {
      console.debug(
        '🚀 ~ ExternalAuthRedirectComponent ~ this.authService.checkAuth ~ loginResponse:',
        loginResponse,
      );
      // TODO: Perform additional actions based on the login response (save connection, redirects, etc.)
    });
  }

  buildCreateConnectionMetadata(integrationType: IntegrationType): Maybe<CreateConnectionMetadata> {
    if (integrationType === IntegrationType.QUICKBOOKS) {
      const urlParams = new URLSearchParams(window.location.search);

      return {
        realmId: urlParams.get('realmId') ?? '',
      };
    }
    return undefined;
  }
}

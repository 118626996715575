import { inject, Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { finalize } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExternalAuthService {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly redirectWorkspaceIdKey = 'external-auth-redirect-workspace-id';

  /**
   * Checks if the user is authenticated, this methods initiates the authorization flow if the user is not authenticated.
   * @param url the URL to perform the authorization on the behalf of the user
   * @param configId the configuration ID to use for the authorization
   * @returns the LoginResponse observable
   */
  public checkAuth(url: string, configId?: string) {
    return this.oidcSecurityService
      .checkAuth(url, configId)
      .pipe(finalize(() => this.clearRedirectWorkspaceId()));
  }

  /**
   * Initiates the authorization flow for the user.
   * @param configId the configuration ID to use for the authorization
   * @param workspaceId the workspaceId to know which workspace the user is trying to access
   */
  public login(configId: string, workspaceId: string) {
    localStorage.setItem(this.redirectWorkspaceIdKey, workspaceId);
    return this.oidcSecurityService.authorize(configId);
  }

  public getRedirectWorkspaceId() {
    return localStorage.getItem(this.redirectWorkspaceIdKey);
  }

  private clearRedirectWorkspaceId() {
    localStorage.removeItem(this.redirectWorkspaceIdKey);
  }
}

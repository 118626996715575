import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from 'app/core/services/auth.service';

/**
 * Initiates the login process by redirecting the user to the identity provider.
 */
@Component({ selector: 'fs-login', template: '' })
export class LoginComponent implements OnInit {
  private readonly activeRoute = inject(ActivatedRoute);
  private readonly authService = inject(AuthService);

  ngOnInit(): void {
    const encodedParam = this.activeRoute.snapshot.queryParamMap.get('url');

    this.authService.login(encodedParam);
  }
}

import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';

import { environment } from 'environments/environment';

export const dashpivotConfig: OpenIdConfiguration = {
  configId: 'auth-config-dashpivot',
  authority: environment.fusionAuth.authority,
  clientId: environment.dashpivot.clientId,
  redirectUrl: `${window.location.origin}${environment.dashpivot.redirectPath}`,
  responseType: 'code',
  scope: 'openid offline_access',
  logLevel: LogLevel.None,
};

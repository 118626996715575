import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';

import { environment } from 'environments/environment';

export const quickbooksConfig: OpenIdConfiguration = {
  configId: 'auth-config-quickbooks',
  authority: environment.production
    ? 'https://developer.api.intuit.com/.well-known/openid_configuration'
    : 'https://developer.api.intuit.com/.well-known/openid_sandbox_configuration',
  clientId: environment.quickbooks.clientId,
  // client_authentication: 'client_secret_basic',
  disablePkce: true,
  redirectUrl: `${window.location.origin}${environment.quickbooks.redirectPath}`,
  responseType: 'code',
  scope: 'com.intuit.quickbooks.accounting',
  authWellknownEndpoints: {
    issuer: 'https://oauth.platform.intuit.com/op/v1',
    authorizationEndpoint: 'https://appcenter.intuit.com/connect/oauth2',
    userInfoEndpoint: environment.production
      ? 'https://accounts.platform.intuit.com/v1/openid_connect/userinfo'
      : 'https://sandbox-accounts.platform.intuit.com/v1/openid_connect/userinfo',
    revocationEndpoint: 'https://developer.api.intuit.com/v2/oauth2/tokens/revoke',
    tokenEndpoint: 'https://oauth.platform.intuit.com/oauth2/v1/tokens/bearer',
  },
  logLevel: LogLevel.None,
};
